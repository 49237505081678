import { useCallback, useEffect, useMemo } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import { IconCheck, IconPencil } from '@tabler/icons-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Column, useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { useApiPagination } from '~/hooks'
import { Form, Input, TableApi } from '~/components'
import { usePartner } from '~/contexts/partner'
import { useUpdatePartnerDealershipConditionsMutation } from '~/graphql/types'
import { toast } from 'react-toastify'

export const BenefitsPercentageTable: React.FC = () => {
  const { myPartner, setMyPartner } = usePartner()
  const methods = useForm()
  const values = useWatch({ control: methods.control })
  const paginationData = useApiPagination({ pageSize: 1000000, currentPage: 0 })
  const [updatePartnerDealerships, { loading: updatePartnerDealershipsIsLoading }] = useUpdatePartnerDealershipConditionsMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if(myPartner) {
        toast.success('As configurações de benefíficos foram salvas!')
        setMyPartner({ ...myPartner, dealerships: data.updatePartnerDealershipConditions.dealerships })
      }
    },
  })

  useEffect(() => {
    myPartner?.dealerships.forEach(item => methods.setValue(item.dealershipName, item.customerDiscountOffered))
  }, [myPartner])

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'UF',
        accessor: 'uf',
      },
      {
        Header: 'Distribuidora',
        accessor: 'dealershipName'
      },
      {
        Header: 'Economia mínima',
        accessor: 'customerDiscountMin',
        Cell: ({ value }) => <>{value}%</>
      },
      {
        Header: 'Economia máxima',
        accessor: 'customerDiscountMax',
        Cell: ({ value }) => <>{value}%</>
      },
      {
        Header: 'Economia sugerida',
        accessor: 'customerDiscountSuggested',
        Cell: ({ value }) => <>{value}%</>
      },
      {
        Header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            Economia praticada
            <Box sx={{
              width: 40,
              padding: 1,
              height: 40,
              display: 'flex',
              borderRadius: '50%',
              background: '#FAFAFA'
            }}>
              <IconPencil size={24} color='#999' />
            </Box>
          </Box>
        ),
        Cell: ({ row: { original } }: any) => {
          return (
            <Input
              value={values.dealershipName}
              onChange={(e) => methods.setValue(original.dealershipName, Number(e.target.value.replace('%', '')) )} 
              name={original.dealershipName}
              mask='percentage' 
            />
          )
        },
        accessor: 'customerDiscountOffered'
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => myPartner?.dealerships || [], [myPartner])

  const tableMethods = useTable(
    {
      columns,
      data: dataWithMemo,
      manualPagination: true,
      defaultColumn: {
        Filter: <></>,
      },
    },
    useFilters,
    useSortBy,
    usePagination,
  )

  const onSubmit = useCallback((data: any) => {
    const formatedData = Object.entries(data)

    const dataToPayload = formatedData.map((item) => {
      const dealershipSelected = myPartner?.dealerships.find(dealership => dealership.dealershipName === item[0])

      return {
        customerDiscountOffered: item[1] as number,
        uf: dealershipSelected?.uf || '',
        dealershipId: dealershipSelected?.dealershipId || '',
        dealershipName: dealershipSelected?.dealershipName || '',
        customerDiscountMax: dealershipSelected?.customerDiscountMax || 0,
        customerDiscountMin: dealershipSelected?.customerDiscountMin || 0,
        customerDiscountSuggested: dealershipSelected?.customerDiscountSuggested || 0,
      }
    })

    updatePartnerDealerships({
      variables: {
        params: {
          conditions: dataToPayload
        }
      }
    })

  }, [myPartner])

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)} style={{ gap: '40px' }}>
        <TableApi
          columns={columns}
          tableLayout='fixed'
          tableMethods={tableMethods}
          paginationData={paginationData}
          options={{ lastColumnAlignRight: true }}
        />

        <Button
          type='submit'
          disabled={updatePartnerDealershipsIsLoading}
          startIcon={updatePartnerDealershipsIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />}
          sx={{ alignSelf: 'end' }}
        >
          Salvar alterações
        </Button>
      </Form>
    </FormProvider>
  )
}
