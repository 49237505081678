import { Box, Divider } from '@mui/material'
import { Card, ContentTitle } from '~/components'
import { BenefitsPercentageTable } from './components'

export const BenefitsSettings: React.FC = () => {
  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>
        <ContentTitle
          title='Porcentagem de benefícios'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description='Edite o campo ”Economia praticada” para alterar a porcentagem do benefício na distribuidora.'
          breadcrumbLinks={{ currentLink: 'Porcentagem de benefícios', links: [{ href: '/app/settings', label: 'Configurações' }] }}
        />
        <Divider />

        <BenefitsPercentageTable />
      </Card>
    </Box>
  )
}
