import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material'
import { IconCalendar, IconCurrencyDollar, IconFileCertificate, IconUsers } from '@tabler/icons-react'
import { Card, PerformanceCard, PerformanceCardProps } from '~/components'
import { formatDate, formatMoney, theme } from '~/utils'
import { useEffect, useMemo, useRef, useState } from 'react'
import ptBR from 'date-fns/locale/pt-BR'
import { useHomePerformancesLazyQuery } from '~/graphql/types'
import { DateRangePicker,  } from 'react-date-range'
import { DateRangeWrapper } from './styles'
import { useOnClickOutside } from '~/hooks'
import staticRangesGenerator from '~/utils/react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

export const PerformanceSection: React.FC = () => {
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))
  const [rangePicker, setRangePicker] = useState([{
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
    key: 'selection',
  }])
  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const refDatePicker = useRef(null)
  useOnClickOutside(refDatePicker, () => setShowDateRangePicker(false))
  const staticRanges = staticRangesGenerator(ptBR)
  
  const [getHomePerformances, { data: homePerformances, loading: homePerformancesIsloading }] = useHomePerformancesLazyQuery()

  const cards: PerformanceCardProps[] = [
    {
      title: 'Indicações totais',
      description: 'Suas indicações',
      value: String(homePerformances?.getIndicationMetrics.indicationCount || 0),
      icon: <IconUsers color='#9E78BC' size={24} />
    },
    {
      title: 'Contratos fechados',
      description: 'Contratos totais',
      value: String(homePerformances?.getIndicationMetrics.signedContract || 0),
      icon: <IconFileCertificate color='#9E78BC' size={24} />
    },
    {
      title: 'Recompensa total',
      description: 'Recompensas por indicação',
      value: formatMoney(homePerformances?.getCommissionMetrics.commissionAmountCents || 0),
      icon: <IconCurrencyDollar color='#9E78BC' size={24} />
    }
  ]

  const performanceCards = useMemo(() => cards.map(card => {
 
    return (
      <PerformanceCard
        isLoading={homePerformancesIsloading}
        key={`performance-card-${card.title}`}
        {...card}
      />
    )
  }), [cards])

  useEffect(() => {
    if (rangePicker[0].startDate !== rangePicker[0].endDate) {
      setShowDateRangePicker(false)
      getHomePerformances({
        variables: {
          paramsComissions: {
            filter: {
              begin: new Date(rangePicker[0].startDate.toISOString().split('T')[0]).toISOString(),
              end: new Date(rangePicker[0].endDate).toISOString()
            }
          },
          paramsIndications: {
            filter: {
              begin: new Date(rangePicker[0].startDate.toISOString().split('T')[0]).toISOString(),
              end: new Date(rangePicker[0].endDate).toISOString()
            }
          }
        }
      })
    }
  }, [rangePicker])

  return (
    <Card sx={{ gap: 3, padding: isLowerSm ? 2 : 4  }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: isLowerMd ? 'flex-start' : 'center' , flexDirection: isLowerMd ? 'column' : 'row' }}>
        <Typography variant='h3' fontWeight={500}>
          Performance
          {isLowerSm && <br />}
          <span style={{ fontSize: '16px', color: theme.palette.grey['400'], marginLeft: isLowerSm ? 0 : '16px' }}>
            Acompanhe suas indicações
          </span>
        </Typography>
        <Tooltip placement='top' title='Selecione um período'>
          <DateRangeWrapper ref={refDatePicker}>
            {!isLowerMd && (
              <Typography onClick={() => setShowDateRangePicker(true)} sx={{ fontSize: '1rem', color: theme.palette.grey[400] }} fontWeight={400}>
              Resultados de
              </Typography>
            )}
            <Typography onClick={() => setShowDateRangePicker(true)} sx={{ fontSize: '1rem', color: theme.palette.grey[800], paddingTop: isLowerSm ? '.4rem' : '0' }} fontWeight={500}>
              {formatDate(rangePicker[0].startDate.toISOString())}
              {' a '}
              {formatDate(rangePicker[0].endDate.toISOString())}
            </Typography>
            <IconCalendar onClick={() => setShowDateRangePicker(true)} size={24} color={theme.palette.primary.main} />
            {showDateRangePicker && (
              <DateRangePicker
                staticRanges={isLowerMd ? [] : staticRanges}
                inputRanges={[]}
                onChange={(teste: any) => setRangePicker([teste.selection])}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={rangePicker}
                direction={isLowerMd ? 'vertical' : 'horizontal'}
                locale={ptBR}
                weekdayDisplayFormat='cccccc'
              />
            )}
          </DateRangeWrapper>
        </Tooltip>

      </Box>

      <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
        {performanceCards}
      </Box>
    </Card>
  )
}
