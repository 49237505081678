import { useCallback, useEffect, useMemo } from 'react'
import { IconCheck, IconCircle, IconCirclePlus, IconEqual, IconInfoCircle } from '@tabler/icons-react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { Box, Button, CircularProgress, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { brazilStates } from '~/constants'
import { Form, Input, Select } from '~/components'
import { maritalStatusOptions, Option } from '~/utils/options'
import { usePartner } from '~/contexts/partner'
import { documentSchema, maritalStatusSchema, phoneSchema, ufSchema } from '~/utils/yupSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { EMaritalStatus, useCreateIndicationMutation } from '~/graphql/types'
import { clearString, formatPercentageToString, transformMoney } from '~/utils'
import { toast } from 'react-toastify'
import { useIndications } from '~/contexts/indications'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@emotion/react'

type FormData = {
  name: string,
  document: string,
  email: string,
  phone: string,
  uf: string,
  dealershipId: string,
  averageEnergyBillValueCents: number
  commissionPercentage: number
  maritalStatus: EMaritalStatus
  nationality: string;
}

export const IndicationsCreateForm: React.FC = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const navigate = useNavigate()
  const { myPartner, getPartnerIsLoading } = usePartner()
  const { setIndications, indications } = useIndications()
  const [onCreateIndication, { loading: createIndicationIsLoading }] = useCreateIndicationMutation({
    onError(error) {
      toast.error(error.message)
    },
    onCompleted(data) {
      if (data.createIndication) {
        setIndications([data.createIndication, ...indications])
        toast.success('A indicação foi registrada!')
        navigate(`/app/indications`)
      }
    },
  })

  const schema = yup.object({
    name: yup.string().required(),
    document: documentSchema,
    email: yup.string().email().required(),
    phone: phoneSchema,
    uf: ufSchema,
    dealershipId: yup.string().required(),
    averageEnergyBillValueCents: yup.number().required(),
    commissionPercentage: yup.number()
      .test('percentage max', (value, { createError, parent }) => {
        const yupDealership = myPartner?.dealerships.find(item => item.dealershipId === parent.dealershipId)
        if (value && yupDealership) {
          const roofPercentage: number = (selectedDealership?.customerDiscountMax || 0) + (myPartner?.partnershipConditions?.baseComissionPercentage || 0)
          if (value > roofPercentage) {
            return createError({
              message: `O percentual de economia não deve ultrapassar de ${roofPercentage}%`,
            })
          } else {
            return true
          }

        } else {
          return true
        }
      })
      .test('percentage min', (value, { createError, parent }) => {
        const yupDealership = myPartner?.dealerships.find(item => item.dealershipId === parent.dealershipId)
        if (value && yupDealership) {
          if (value < (yupDealership?.customerDiscountMin || 0)) {
            return createError({
              message: `O percentual de economia não deve ser menor que ${formatPercentageToString(yupDealership?.customerDiscountMin)}.`,
            })
          } else {
            return true
          }

        } else {
          return true
        }
      }).required(),
    maritalStatus: maritalStatusSchema,
    nationality: yup.string().required(),
  }).required()

  const formMethods = useForm<FormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      uf: '',
      commissionPercentage: 0,
      maritalStatus: '' as EMaritalStatus,
    }
  })

  const { uf, dealershipId, commissionPercentage } = useWatch({ control: formMethods.control })
  const selectedDealership = myPartner?.dealerships.find(item => item.dealershipId === dealershipId)

  useEffect(() => {
    if (dealershipId) {
      formMethods.setValue('commissionPercentage', selectedDealership?.customerDiscountOffered || 0)
    }
  }, [dealershipId])

  useEffect(() => {
    if (uf) {
      formMethods.setValue('commissionPercentage', 0)
      formMethods.setValue('dealershipId', '')
    }
  }, [uf])

  const dealershipOptions: Option[] = useMemo(() => myPartner?.dealerships?.filter(dealership => dealership.uf === uf)
    .map(dealership => {
      return { value: dealership.dealershipId, label: dealership.dealershipName || '' }
    }), [myPartner, uf]) || []

  const onSubmit = useCallback((data: FormData) => {

    onCreateIndication({
      variables: {
        params: {
          averageEnergyBillValueCents: transformMoney(data.averageEnergyBillValueCents, 'toCents'),
          dealershipId: data.dealershipId,
          dealerShipName: dealershipOptions.find(item => item.value === data.dealershipId)?.label || '',
          document: clearString(data.document),
          email: data.email,
          name: data.name,
          phone: clearString(data.phone),
          uf: data.uf,
          commissionPercentage: data.commissionPercentage,
          maritalStatus: data.maritalStatus,
          nationality: data.nationality
        }
      }
    })
  }, [dealershipOptions])

  const filtersStates = useMemo(() => {
    return brazilStates.filter(state => state.value === myPartner?.dealerships.find(dealership => dealership.uf === state.value)?.uf)
  }, [myPartner])

  const hasDealership = Boolean(dealershipId)
  const hasCommissionPercentage = Boolean(commissionPercentage)

  const comissionPercentageIsLowerToDiscountMax = (commissionPercentage || 0) <= (selectedDealership?.customerDiscountMax || 0)
  const roofPercentage = (selectedDealership?.customerDiscountMax || 0) + (myPartner?.partnershipConditions?.baseComissionPercentage || 0)

  const aditionalComission = comissionPercentageIsLowerToDiscountMax
    ? Number(Number((selectedDealership?.customerDiscountMax || 0) - (commissionPercentage || 0)).toFixed(2)) / 2
    : Number(Number((selectedDealership?.customerDiscountMax || 0) - (commissionPercentage || 0)).toFixed(2))

  const finalComission = (myPartner?.partnershipConditions?.baseComissionPercentage || 0) + aditionalComission

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={formMethods.handleSubmit((data) => onSubmit(data))}>
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 3 }}>
          <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Dados</Typography>
          <Grid spacing={3} container>
            <Grid item md={8} xs={12}>
              <Input name='name' label='Nome completo' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input name='document' label='CPF/CNPJ' mask='cpfCnpj' />
            </Grid>

            <Grid item md={4} xs={12}>
              <Input name='email' label='E-mail' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input name='phone' label='Telefone' mask='phone' />
            </Grid>
            <Grid item md={4} xs={12}>
              <Select variant='outlined' name='uf' label='UF' options={filtersStates} />
            </Grid>
            <Grid item md={4} xs={12}>
              <Select
                name='dealershipId'
                variant='outlined'
                options={dealershipOptions}
                disabled={getPartnerIsLoading}
                label='Concessionária de energia'
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Input name='averageEnergyBillValueCents' label='Valor médio de consumo' mask='BRL' />
            </Grid>
            <Grid item md={4} xs={12}>
            </Grid>
            <Grid xs={12} md={4} item>
              <Input label='Nacionalidade' name='nationality' />
            </Grid>
            <Grid xs={12} md={4} item>
              <Select label='Estado civil' name='maritalStatus' options={maritalStatusOptions} />
            </Grid>
          </Grid>

          <Grid container>

            <Grid item xs={12}>
              <Typography sx={{ padding: '0 0 1rem 0' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Percentual de economia</Typography>
            </Grid>

            <Grid item xs={12}>
              {Boolean(selectedDealership) && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '.4rem', padding: '0 0 1rem 0' }}>
                  <IconInfoCircle color={theme.palette.info.dark} />
                  <Box>
                    <Typography sx={{ fontWeight: 500 }} variant='body1' color={theme.palette.grey[900]}>
                      O valor deve estar entre {selectedDealership?.customerDiscountMin}% e {roofPercentage}%* para a concessionária informada
                    </Typography>
                    <Typography variant='body1' color={theme.palette.grey[600]}>
                      *em que {selectedDealership?.customerDiscountMax}% são do valor máximo de desconto para o cliente e {myPartner?.partnershipConditions?.baseComissionPercentage}% são substraídos da sua comissão base.
                    </Typography>
                  </Box>
                </Box>
              )}

            </Grid>
            <Grid item md={4} xs={12}>
              <Input disabled={!hasDealership} name='commissionPercentage' label='Percentual de economia' mask='percentage' />
            </Grid>
          </Grid>

          <Box sx={{ background: '#fafafa', border: `1px solid ${theme.palette.grey[300]}`, padding: '1.4rem', borderRadius: '.8rem' }}>
            <Typography sx={{ paddingBottom: '1rem' }} variant='h3' fontWeight={500} color={theme.palette.grey[800]}>Sua comissão</Typography>
            <Box sx={{ display: 'flex', gap: isLowerSm ? '1rem' : '2rem', alignItems: isLowerSm ? 'flex-start' : 'center', flexFlow: isLowerSm ? 'column' : 'row' }}>
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                <Typography variant='body2' color={theme.palette.grey[600]}>Comissão base</Typography>
                <Typography variant='h3' fontWeight={500} color={theme.palette.grey[800]}>{myPartner?.partnershipConditions?.baseComissionPercentage}%</Typography>
              </Box>
              <IconCirclePlus color={theme.palette.secondary.main} size={18} />
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                <Typography variant='body2' color={theme.palette.grey[600]}>Comissão adicional</Typography>
                <Typography variant='h3' fontWeight={500} color={hasCommissionPercentage ? theme.palette.grey[800] : theme.palette.grey[300]}>{aditionalComission}%</Typography>
              </Box>
              <Box sx={{ position: 'relative' }}>
                <IconCircle color={theme.palette.secondary.main} size={18} />
                <IconEqual color={theme.palette.secondary.main} size={14} style={{ position: 'absolute', top: '2px', left: '2px' }} />
              </Box>
              <Box sx={{ display: 'flex', flexFlow: 'column', gap: '.2rem' }}>
                <Typography variant='body2' color={theme.palette.grey[600]}>Comissão final</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}>
                  <IconCheck color={hasCommissionPercentage ? theme.palette.success.main : theme.palette.grey[300]} />
                  <Typography variant='h3' fontWeight={500} color={hasCommissionPercentage ? theme.palette.grey[800] : theme.palette.grey[300]}>{finalComission}%</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider />

          <Box sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
            <Button color='secondary'>
              Cancelar
            </Button>

            <Button
              disabled={createIndicationIsLoading}
              type='submit'
              startIcon={createIndicationIsLoading ? <CircularProgress size={24} color='inherit' /> : <IconCheck size={24} />
              }>
              Confirmar
            </Button>
          </Box>
        </Box>
      </Form>
    </FormProvider>
  )
}
